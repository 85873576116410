// FinishList.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TimeAgo } from '../components/TimeAgo';
import { Strapi } from '../functions/Strapi';
import { formatTimeAgo } from '../functions/formatTimeAgo';
import "./FinishList.scss";

const FinishList = () => {
    const [Article, setArticle] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Count, setCount] = useState(0);
    const [reloadTrigger, setReloadTrigger] = useState(0); // For restarting animation

    const fetchData = async () => {
        try {
            const response = await axios({
                url: Strapi.API_ADDRESS + "/articles?sort=updatedAt:DESC&populate[0]=author&populate[1]=audio&populate[2]=poll&populate[3]=titleImage&populate[4]=events&filters[ownArticle][$eq]=true",
                headers: Strapi.DEFAULT_HEADERS,
                method: "GET"
            });
            setArticle(response.data?.data);
            setCount(response.data?.meta?.pagination?.total);
            setLoading(false);
            setReloadTrigger(prev => prev + 1); // Restart animation
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 10000); // 10 seconds

        return () => clearInterval(interval);
    }, []);

    if (Loading) return <p>Loading...</p>
    return (
        <div className='FinishList p-3 relative'>
            <h2 className="text-lg font-semibold mb-4">Completed Articles ({Count})</h2>
            {/* Circle Indicator */}
            <div className="reload-circle">
                <svg className="circular" viewBox="0 0 50 50">
                    <circle
                        className="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        strokeWidth="5"
                    />
                </svg>
            </div>
            {/* Article List */}
            {Article.map((i, k) => <ArticleItem {...i} key={k} />)}
        </div>
    );
};

export default FinishList;

function ArticleItem({ id, attributes }) {
    let { title, slug, publicTimestamp, publishedAt, createdAt, author = {}, titleImage, titleImageObject, events } = attributes;
    const [timeAgo, setTimeAgo] = useState(formatTimeAgo(publishedAt));
    const [Clicked, setClicked] = useState(false);

    let imageSmallUrl = titleImageObject?.data?.attributes?.formats?.thumbnail?.url || false;

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(formatTimeAgo(publishedAt));
        }, 1000);

        return () => clearInterval(interval);
    }, [publishedAt]);

    let authorSlug = author?.data?.attributes?.slug;
    if (!slug) return <p>No SLUG!!! ID: {id}</p>
    if (!authorSlug) return <p>No authorSlug!!! ID: {id}</p>
    let url = "https://mittelhessen.app/p/" + authorSlug + "/" + slug;

    function handleClick() {
        setClicked(true);
    }

    return <div className={'item ' + (Clicked && "clicked")}>
        <div className='d-flex flex-row'>

            {imageSmallUrl ? <img style={{ width: 64, height: 64 }} src={Strapi.MAIN_ADDRESS + imageSmallUrl} alt="Article Thumbnail" /> : <div style={{ width: 64, height: 64, background: 'red' }}></div>}
            <a href={url} target='_blank' rel="noopener noreferrer" onClick={handleClick} className='d-flex flex-row'>
                <div>
                    <span className='title'>{title}</span>
                    <TimeAgo title="Internal Public Date">{publishedAt}</TimeAgo>
                    <HighlightedItems data={attributes} />
                </div>
            </a>
        </div>
    </div>
}

function isValidValue(value) {
    if (value === null || value === false || value === "false") return false;
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    if (typeof value === 'object') {
        if (value.hasOwnProperty('data')) {
            if (Array.isArray(value.data)) {
                return value.data.length > 0;
            }
            return isValidValue(value.data);
        }
        return Object.values(value).some(v => isValidValue(v));
    }
    return true;
}

function HighlightedItems({ data = {} }) {
    // Filter highlightedKeys to include only those keys that exist in the data and have valid values

    const filteredHighlightedKeys = highlightedKeys.filter(item => {
        const value = data[item.key];
        if (item.key === 'events') {
            return value?.data?.length
            return value && Array.isArray(value.data) && value.data.length > 0;
        } else {
            return item.key in data && isValidValue(value);
        }
    });

    const eventCount = data?.events?.data?.length ?? 0;
    const hasEvents = eventCount > 0;

    return (
        <div className='highlightedItems d-flex flex-row'>

            {filteredHighlightedKeys.map((item, index) => (
                <HighlightedItem {...item} value={data[item.key]} key={index} />
            ))}


            {hasEvents &&
                <div className='d-flex flex-row ps-4'>
                    <HighlightedItem title="Event" key="events" icon="calendar" emoji="📅" value={data.events} />
                    <span className='ms-1'>{eventCount}x</span>
                </div>
            }

        </div>
    );
}

function HighlightedItem({ title, key, icon, emoji, value }) {
    let displayValue = null;
    if (key === 'events') {
        displayValue = value?.data?.length ?? null;
    }
    return (
        <div title={`Der Beitrag erhält: ${title}`} className='highlightedItem'>
            <span className='icon'>{emoji}</span>
            {displayValue != null && <span className='count'>{displayValue}</span>}
        </div>
    );
}

const highlightedKeys = [
    {
        title: "Baustelle",
        key: "detourJson",
        icon: "road",
        emoji: "🚧",
    },
    {
        title: "Einfache Sprache",
        key: "textEasySpeech",
        icon: "text",
        emoji: "🦽",
    },
    {
        title: "Audio-Version",
        key: "audio",
        icon: "audio",
        emoji: "🔊",
    },
    {
        title: "Video-Version",
        key: "youtubeID",
        icon: "video",
        emoji: "📹",
    },
    {
        title: "Instagram",
        key: "instagramText",
        icon: "instagram",
        emoji: "📸",
    },
    {
        title: "Umfrage",
        key: "poll",
        icon: "survey",
        emoji: "📊",
    },
    // {
    //     title: "Event",
    //     key: "events",
    //     icon: "calendar",
    //     emoji: "📅",
    // },
];
